import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ConfirmationConfig } from '@app/types/shared.types';
import { BUTTON_TYPE } from '@dvm/components';

export const CONFIRMATION_CONFIG: ConfirmationConfig = {
  title: '',
  text: '',
  type: 'danger',
  mainAction: 'Yes, Delete',
  cancelAction: 'No, Cancel',
  buttonType: BUTTON_TYPE.PRIMARY,
};

export const ACCEPTED_FILE_TYPES = {
  CSV: { type: 'text/csv', name: 'CSV' },
};

export const IMPORT_CUSTOMER = {
  Group: 'group',
  Name: 'name',
  Address: 'address',
  City: 'city',
  Phone: 'phone',
  Fax: 'fax',
  Mobile: 'mobile',
  Email: 'eMail',
  ExtCustomerNr: 'extCustomerNr',
  Note: 'note',
  Active: 'active',
};

export const SET_NUMBER = {
  max_length_5: 5,
  max_length_10: 10,
  max_length_20: 20,
  max_length_30: 30,
  max_length_40: 40,
  max_length_45: 45,
  max_length_50: 50,
  max_length_100: 100,
  max_length_120: 120,
  max_length: 255,
  max_length_500: 500,
};

export enum OverviewType {
  Time = 'time',
  Product = 'product',
  Mileage = 'mileage',
  Allowance = 'allowance',
  Assignment = 'assignment',
}

export const OVERLAY_POSITIONS: ConnectionPositionPair[] = [
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  },
];

export const OVERLAY_TOP_POSITIONS: ConnectionPositionPair[] = [
  {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
  },
];

export const OVERLAY_CENTER_POSITIONS: ConnectionPositionPair[] = [
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
  },
];

export const PERCENTAGE = {
  HUNDRED: 100,
};

export const KEY_CODE = {
  SPACE: 32,
};

export const DAY_HOURS = 24;

export const ABSENCE = {
  VACATION: 'Vacation',
  SICK: 'Sick',
  FREE: 'Free',
  CARE_OF_CHILD: 'Care of child',
  PARENTAL_LEAVE: 'Parental leave',
};

export const ONE_MINUTE_SLOT = 0.0166666667;

export enum AssignmentPriority {
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3,
}

export const AssignmentPriorityTitle = {
  [AssignmentPriority.HIGH]: 'High',
  [AssignmentPriority.MEDIUM]: 'Medium',
  [AssignmentPriority.LOW]: 'Low',
};
