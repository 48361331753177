import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToasterComponent } from '@app/components/toaster/toaster.component';
import { TranslateService } from '@dv/translate';
import { NOTICE_TYPE } from '@dvm/components';
@Injectable({
  providedIn: 'root',
})
export class AlertToasterService {
  constructor(
    private matSnackbar: MatSnackBar,
    public translateService: TranslateService,
  ) { }

  displaySnackBarWithoutTranslation(message: string, type: NOTICE_TYPE, title?: string): void {
    if (message) {
      this.matSnackbar.openFromComponent(ToasterComponent, {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        data: {
          type,
          message,
          title,
        },
        panelClass: 'snackbar'
      });
    }
  }

  displaySnackBarWithTranslation(message: string, type: NOTICE_TYPE, title?: string): void {
    const translateMessage = this.translateService.t(message);
    if (translateMessage) {
      this.matSnackbar.openFromComponent(ToasterComponent, {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        data: {
          type,
          message,
          title,
        },
        panelClass: 'snackbar'
      });
    }
  }
}
