import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {
  DvmNoticeComponent,
  DvmUnaryAttributeDirective,
  NOTICE_TYPE,
} from '@dvm/components';

@Component({
  selector: 'app-toaster',
  standalone: true,
  imports: [DvmNoticeComponent, DvmUnaryAttributeDirective],
  templateUrl: './toaster.component.html',
})
export class ToasterComponent implements OnInit {
  type: NOTICE_TYPE = NOTICE_TYPE.SUCCESS;
  message = '';
  title = '';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      type: NOTICE_TYPE;
      message: string;
      title?: string;
    },
  ) {}

  ngOnInit(): void {
    this.type = this.data.type;
    this.message = this.data.message;
    this.title = this.data.title ?? '';
  }
}
